<!--
 * @Descripttion: 
 * @version: 1.0.0
 * @Author: Aggie
 * @Date: 2023-09-26 10:35:48
-->
<template>
  <div>
    <div class="centerBox">
      <div class="center_title">
        <div>
          <img v-if="item.SupplierThumbnail" :src="item.SupplierThumbnail" />
          <img v-else src="../../../assets/images/icar.png" />
          <p>{{ item.SupplierName }}</p>
        </div>

        <span :class="['status', item.Status == '10' || item.Status == '30' || item.Status == '60' ? 'yellowFont' : 'color']">{{ item.StatusTxt }}</span>
      </div>
      <span class="contactBox" v-for="(contact, contentIndex) in item.SupplierContacts" :key="contentIndex">
        <a @click.stop="ihtml.telCall(contact.Mobile)" :class="contact.typeContacter == 1 ? 'redfont' : 'blurfont'">{{ contact.Name }}({{ contact.Mobile }})</a>
      </span>
      <div class="priceBox">
        <span>实际金额 </span>
        <span>¥{{ Number(item.SettleAmount).toFixed(2) }}</span>
      </div>
      <div class="miceInfo">
        <div><span>会议名称</span> {{ item.PName }}</div>
        <div><span>活动或会议编号</span> {{ item.ExtNum }}</div>
        <div><span>会议时间</span> {{ item.DtStart | formatDate('yyyy-MM-dd') }}~{{ item.DtEnd | formatDate('yyyy-MM-dd') }}</div>
        <div>
          <span>订单创建人</span> {{ item.DspName.includes('spc-') ? item.DspName.split('spc-')[1] : item.DspName }}（{{ item.UserName.includes('spc-') ? item.UserName.split('spc-')[1] : item.UserName }}）
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style></style>
