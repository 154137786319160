<template>
  <div>
    <div class="fixBox">
      <div class="opSearchRows">
        <Svgs color="#13161B" classes="opChooseList" name="iconfanhui" @click.native="platformApprovalback()" />
        <SearchRow @search-call="SearchOrder(true)" :placeholder="placeholder" v-model="keyWord" class="svg_icon"></SearchRow>
      </div>
      <div class="tabBoxs">
        <li @click="changeTabs(btn)" :class="[tabBar.Txt == btn.Txt && 'selectedTab']" v-for="(btn, tindex) in orderTabs" :key="tindex">
          <div>{{ btn.Txt }}({{ btn.Count }})</div>
          <span></span>
        </li>
      </div>
    </div>

    <ScrollBox class="opOrderListScroll" @on-top="scrollTop" @on-bottom="scrollBottom" :loadType="loadType">
      <template v-slot:scrollBox>
        <div v-if="dataList.length" class="approvalMain">
          <ul>
            <li v-for="(item, index) in dataList" :class="[flag == index && 'choiseOp', backListOn == index && 'opHomeOn']" :key="index" :id="'opOrderList' + index">
              <a :id="'listHref' + index" :href="'#opOrderList' + index" style="display: none" @click="stopSt"></a>
              <div v-if="$route.query.IsOnline == 'true'" class="pindex" @click="go_detail(item, {}, index)">
                <pIndexItem :item="item" :index="index" :btnClick="btnClick" :isloding="isloding"></pIndexItem>
              </div>
              <div v-else @click="goItem(item)" class="cindex">
                <cIndexItem :item="item"></cIndexItem>
              </div>
            </li>
          </ul>
        </div>
        <Skeleton v-if="isFirst" :skList="skList" />
        <NoData v-if="!dataList.length && loadType == '' && !isFirst"></NoData>
      </template>
    </ScrollBox>
    <MessageBox ref="tipsMsg" type="tips" :messageTitle="messageTitle" :messageTxt="messageTxt" @on-ok="MessageBtn()"></MessageBox>
    <returnBox :retype="retype" @closeDialog="isRebackDialog = false" @quotePiece="PerformTask" :isloding="isloding" v-if="isRebackDialog"></returnBox>
  </div>
</template>

<script>
import cIndexItem from '@/views/common/approval/cIndexItem';
import pIndexItem from '@/views/common/approval/pIndexItem';
import returnBox from '@/views/common/returnBox';
import choiseExpenseItem from '@/views/controllers/choiseExpenseItem.js';
import submitOrder from '@/views/controllers/submitOrder.js';
import filterEmoji from '@/views/controllers/filterEmoji.js';
export default {
  mixins: [choiseExpenseItem, submitOrder, filterEmoji],
  data() {
    return {
      flag: -1,
      placeholder: '请输入乘车人、会议名称qqqq.',
      messageTxt: '',
      messageTitle: '提示',
      isloding: true,
      keyWord: '',
      dataList: [],
      total: 0,
      loadType: '',
      skList: [
        {
          width: '65%',
          row: 5
        },
        {
          width: '20%',
          position: 'right'
        },
        {
          width: '40%',
          inline: true
        },
        {
          width: '50%'
        },
        {
          width: '30%',
          ml: '.1rem'
        },
        {
          width: '15%',
          inline: true
        },
        {
          width: '100%',
          inline: true
        },
        {
          width: '0',
          inline: true
        }
      ],
      isFirst: true,
      pageIndex: 1,
      pageSize: 10,
      tabBar: {},
      btnData: [],
      choiseData: {},
      orderTabs: [],
      passengersList: [],
      isPerformTask: false,
      isRebackDialog: false,
      retype: ''
    };
  },
  components: {
    cIndexItem,
    pIndexItem,
    returnBox
    // "van-search": Search,
  },
  created() {
    this.setRouteListeners('platformApprovalback');
    this.$root.$eventHub.$on('platformApprovalback', data => {
      this.platformApprovalback();
      this.$root.$eventHub.$off('platformApprovalback'); //解绑当前监听，否则下轮监听还会持续叠加
    });
    this.placeholder = '请输入会议编号、名称';
    let tabBar = this.SmartStorage.get('orderlistStatu');
    if (tabBar) {
      this.tabBar = tabBar;
    }
    this.StatByStatus();
  },
  methods: {
    goItem(data) {
      let carOrder = {
        orderId: data.BusinessId,
        SupplierThumbnail: data.SupplierThumbnail,
        itsExtData: { supplierName: data.SupplierName },
        SupplierContacts: data.SupplierContacts,
        CityDictVal: data.CityDictVal
      };
      this.SmartStorage.set('mice_proposalId', data.ProcessId);
      this.SmartStorage.set('orderDetail', data);
      this.SmartStorage.set('orderlistStatu', this.tabBar);
      this.SmartStorage.set('carOrder', carOrder);
      this.$router.push({
        path: '/carAccount',
        query: {
          isApproval: true
        }
      });
    },
    onSearch() {},
    MessageBtn() {
      this.SmartStorage.set('orderlistStatu', this.tabBar);
      console.log(this.passengersList);
      this.isPerformTask
        ? this.PerformTask()
        : this.$router.push({
            path: '/approvalPassenger',
            query: {
              passengersList: this.passengersList
            }
          });
    },
    changeTabs(btn) {
      this.$iDelay(() => {
        this.tabBar = btn;
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.pageIndex = 1;
        this.SearchOrder(true);
      }, 200);
    },
    //监听滚动
    loadmoreScroll(event) {
      this.scrollTop = event.target.scrollTop;
    },
    async go_detail(item, btn, index) {
      await this.QueryPassenger(item);
      item.ProposalId = item.ProcessId;
      item.ItemId = item.BusinessId;
      this.SmartStorage.set('orderlistStatu', this.tabBar);
      this.SmartStorage.set('orderDetail', item);
      this.setListOn(index);
      this.$router.push({
        path: '/orderDetail',
        query: {
          isApproval: true,
          passengersList: this.passengersList
        }
      });
    },
    go_record(item, btn, index) {
      this.setListOn(index);
      this.SmartStorage.set('orderlistStatu', this.tabBar);
      this.$router.push({
        path: '/approvalLog',
        query: {
          businessId: item.BusinessId,
          ProcessId: item.ProcessId
        }
      });
    },
    async go_submit(item, btn) {
      await this.QueryPassenger(item);
      this.isPerformTask = false;
      item.ActionId = btn.ActionId;
      this.SmartStorage.set('orderDetail', item);
      this.messageTxt = '【实际乘车人】请务必填写所有乘车人的完整姓名';
      this.$refs.tipsMsg.openPop();
    },
    async GetCurrentTaskId(item) {
      let params = {
        BusinessId: item.BusinessId,
        ProcessId: item.ProcessId,
        FlowCode: 'TMIS-DSM-Car-Approval'
      };
      this.services.GetCurrentTaskId(params).then(res => {
        if (res.success) {
          this.SmartStorage.set('TaskId', res.content);
        }
      });
    },
    PerformTask(actDescr) {
      this.isloding = false;
      let params = {
        taskId: this.SmartStorage.get('TaskId'),
        ActCode: this.btnData.Processor,
        ProcessId: this.choiseData.ProcessId,
        BusinessId: this.choiseData.BusinessId,
        actDescr: this.EmojiExchangeString(actDescr) || ''
      };
      this.services.PerformTask(params).then(res => {
        if (res.success) {
          this.$iToast('提交成功！');
          this.StatByStatus();
        }
        this.isRebackDialog = false;
        this.isloding = true;
      });
    },
    async QueryPassenger(item) {
      let params = {
        BusinessId: item.BusinessId
      };
      await this.services.QueryPassenger(params).then(res => {
        if (res.success) {
          res.content.forEach(element => {
            element.passengers = this.StringExchangeEmoji(element.passengers);
            element.organization = this.StringExchangeEmoji(element.organization);
            element.department = this.StringExchangeEmoji(element.department);
            element.remark = this.StringExchangeEmoji(element.remark);
          });
          this.passengersList = res.content;
        }
      });
    },
    async btnClick(item, btn, index) {
      this.choiseData._id != item._id && (await this.GetCurrentTaskId(item));
      this.btnData = btn;
      this.choiseData = item;
      switch (btn.Processor) {
        case 'submit':
        case 'detail':
        case 'record':
          this['go_' + btn.Processor](item, btn, index);
          break;
        case 'reback':
        case 'refused':
          this.retype = btn.Processor;
          this.isRebackDialog = true;
          break;
        default:
          this.messageTxt = btn.Processor == 'refused' ? '否决后订单无法再次提交审批，叫车人将自行承担相关费用' : '确定' + btn.Txt + '审批吗？';
          this.messageTitle = btn.Processor == 'refused' ? '否决审批' : '提示';
          this.$refs.tipsMsg.openPop();
          this.isPerformTask = true;
          break;
      }
    },
    platformApprovalback() {
      this.removeRouteListeners('platformApprovalback');
      this.$router.push('/approvalIndex');
      this.SmartStorage.remove('orderlistStatu');
    },
    //获取数量
    async StatByStatus() {
      let params = {
        IsByCategory: false,
        Filter: {
          IsOnline: this.$route.query.IsOnline === 'true'
        }
      };
      //从更多进
      if (this.SmartStorage.get('proposalId')) {
        params.Filter.ProcessId = this.SmartStorage.get('proposalId');
        params.isAuthorized = this.SmartStorage.get('eventData').isFromMore ? true : false;
      } else {
        params.IsByMice = false;
      }
      let _this = this;
      this.services.dsm_StatByStatus(params).then(function(res) {
        if (res.success) {
          _this.orderTabs = res.content;
          _this.tabBar = _this.tabBar.Txt ? _this.tabBar : _this.orderTabs[0];
          _this.pagerIndex = _this.keyWord ? 1 : _this.keyWord;
          _this.SearchOrder(true);
        }
      });
    },
    //获取订单列表
    async SearchOrder(clear) {
      let params = {
        Code: this.tabBar.Code,
        Filter: {
          IsOnline: this.$route.query.IsOnline === 'true'
        },
        pagerIndex: this.pageIndex,
        pagerSize: this.pageSize,
        sort: {
          DtStart: -1
        }
      };
      if (this.SmartStorage.get('proposalId')) {
        params.Filter.ProcessId = this.SmartStorage.get('proposalId');
        params.isAuthorized = this.SmartStorage.get('eventData').isFromMore ? true : false;
      } else {
        params.IsByMice = false;
      }
      if (this.keyWord) {
        params.Filter.$or =
          this.$route.query.IsOnline === 'true'
            ? [
                {
                  PassengerName: '/' + this.keyWord + '/'
                },
                {
                  PName: '/' + this.keyWord + '/'
                }
              ]
            : [
                {
                  ExtNum: '/' + this.keyWord + '/'
                },
                {
                  PName: '/' + this.keyWord + '/'
                }
              ];
      }
      let _this = this;
      this.services.dsm_Paging(params).then(function(res) {
        _this.isFirst = false;
        if (res.success) {
          if (clear == true) {
            _this.dataList = [];
          }
          let eventList = res.content.rows;
          for (let i = 0; i < eventList.length; i++) {
            // eventList[i].DtStartDay = this.$publicFun.returnDate(eventList[i].dtStart);
            // eventList[i].DtEndDay = this.$publicFun.returnDate(eventList[i].dtEnd);
            _this.dataList.push(eventList[i]);
          }
          _this.total = res.content.total;
          _this.loadType = '';
        }
      });
    },
    scrollTop(filter) {
      this.loadType = 'top';
      this.pageIndex = 1;
      if (filter) {
        this.dataList = [];
      }
      this.StatByStatus();
    },
    scrollBottom() {
      if (this.pageSize * this.pageIndex >= this.total) {
        this.loadType = 'none';
      } else {
        this.loadType = 'bottom';
        this.pageIndex += 1;
        this.SearchOrder();
      }
    }
  }
};
</script>

<style lang="sass">
@import '@/assets/approval/approvalIndex.scss'
</style>
