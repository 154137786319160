import { render, staticRenderFns } from "./returnBox.vue?vue&type=template&id=031bdc56&scoped=true&"
import script from "./returnBox.vue?vue&type=script&lang=js&"
export * from "./returnBox.vue?vue&type=script&lang=js&"
import style0 from "./returnBox.vue?vue&type=style&index=0&id=031bdc56&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "031bdc56",
  null
  
)

export default component.exports