<template>
  <div class="dialogbox carAccountDialog">
    <div class="maskBox"></div>
    <div class="brnBox">
      <div class="titleBox">
        <a>{{ title }}</a>
        <span class="closeBtn" @click="closeDialog">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconclose" />
          </svg>
        </span>
      </div>
      <div class="choseBtn">
        <p v-if="desc">{{desc}}</p>
        <input
          v-if="isquote"
          placeholder="请输入报价金额"
          class="choseInput"
          style="font-size: 0.14rem"
          value=""
          type="number"
          v-model="quotePieceNum"
        />
        <textarea
          v-else
          :placeholder="placeholder"
          class="choseInput"
          style="font-size: 0.14rem; height: 1rem"
          v-model="actDescr"
          :disabled="!isShowBtn"
        ></textarea>
        <span
          @click="isloding && quotePiece()"
          :class="['submitBtn', !isloding && 'isloding']"
          v-if="isShowBtn"
          >确认</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isquote", "isloding", "quotePieceNum","retype","carRemark","showBtn"],
  data() {
    return {
      isShowBtn:true,
      actDescr: "",
      title:"",
      desc:"",
      placeholder:"请填写原因"
    };
  },
  components: {},
  created() {},
  mounted() {
    switch (this.retype) {
      case 'refused':
        this.title="请录入否决原因";
        this.desc="否决订单，订单将直接转为否决状态，叫车人将自行承担相关费用";
        this.placeholder="请填写原因"
        break;
      case 'carRemark':
        this.isShowBtn=this.showBtn;
        this.actDescr=this.carRemark;
        this.title="用车说明";
        this.desc="";
        this.placeholder="请填写用车说明"
        break;
      default:
        this.title="请录入退回原因";
        this.desc="退回审批，由叫车人重新提交审批";
        this.placeholder="请填写原因"
        break;
    }
  },
  methods: {
    quotePiece() {
      this.$iDelay(() => {
        let data = this.isquote ? this.quotePieceNum : this.actDescr;
        this.$emit("quotePiece", data);
      }, 200);
    },
    closeDialog() {
      this.$emit("closeDialog", true);
    },
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/approval/approvalIndex.scss'
</style>