<!--
 * @Descripttion: 
 * @version: 1.0.0
 * @Author: Aggie
 * @Date: 2023-09-05 18:31:51
-->
<template>
  <div>
    
    <div class="itemHeadBox">
      <div class="itemHead_title">
        <div class="sourceBox">
          <svg class="icon" aria-hidden="true">
          <use :xlink:href="'#' + item.SourceIcon" />
        </svg>
          <p>{{ item.SourceCodeTxt }}</p>
          <span>
            <a>{{ item.Rule }}</a>
            <a v-if="item.TypeTxt && item.TypeTxt != 'null'">{{ item.TypeTxt }}</a>
          </span>
        </div>
        <a class="statusBox">{{ item.StatusTxt }}</a>
      </div>
    </div>
    <div class="priceBox">
      <p>
        <svg class="icon" aria-hidden="true"><use xlink:href="#icon-shijian" /></svg>
        <template v-if="item.DepartureTime && item.DepartureTime != 'null'">
          {{ item.DepartureTime| formatDate('yyyy-MM-dd hh:mm') }}
        </template>
        <template v-else>暂无</template>
      </p>
      <p>
        <a>{{ item.SettleAmount ? '实际金额' : '预计金额' }}</a>
        {{ item.SettleAmount ? item.SettleAmount : item.BudgetAmount || 0 | currencyFormatter }}
        <i v-if="item.SourceCode == 'GaoDe' && item.Status != 5" class="upTxt">起</i>
      </p>
    </div>
    <div class="addressBox">
      <div>
        <span class="greenBc"></span>
        {{ item.StartName }}
      </div>
      <div>
        <span class="orangeBc"></span>
        {{ item.EndName }}
      </div>
    </div>

    <div class="contancBox">
      <p>
        会议名称
        <span>{{ item.PName }}</span>
      </p>
      <p>
        活动或会议编号
        <span>{{ item.ExtNum || '暂无' }}</span>
      </p>
      <p>
        会议时间
        <span>{{ item.DtStart | formatDate('yyyy-MM-dd') }}~{{ item.DtEnd | formatDate('yyyy-MM-dd') }}</span>
      </p>
      <p>
        订单创建人
        <span>
          {{ item.DspName.includes('spc-') ? item.DspName.split('spc-')[1] : item.DspName }} （{{ item.UserName.includes('spc-') ? item.UserName.split('spc-')[1] : item.UserName || '暂无' }}）
        </span>
      </p>
    </div>
    <div :class="['btnList', item.ItsButtons.length > 3 && 'gaodeBtns']">
      <div class="dropDownBox" v-if="isDrop">
        <span v-for="(btnd, bindexd) in item.ItsButtons.slice(4, item.ItsButtons.length)" :key="bindexd" @click.stop="isloding && btnClick(item, btnd, index)">{{ btnd.Txt }}</span>
      </div>
      <a class="moreBtn" v-if="item.ItsButtons.length > 4" @click.stop="isDrop = !isDrop">更多</a>
      <a
        :class="['btnLength' + item.ItsButtons.length, !isloding && 'isloding']"
        v-for="(btn, bindex) in item.ItsButtons.slice(0, 4)"
        :key="bindex"
        @click.stop="isloding && btnClick(item, btn, index)"
      >
        {{ btn.Txt }}
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: ['item', 'btnClick', 'isloding', 'index'],
  data() {
    return {
      isDrop: false
    };
  },
  methods: {}
};
</script>
<style lang="sass" scoped></style>
